import React, { createContext, useReducer, useRef, useMemo, useCallback, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { remoteAssistanceSecureConnectVersion } from 'API_routes'
import { remoteAssistanceUserActionMap } from 'common/constants'
import { RemoteActionId, QueryType } from 'common/enums'
import { IResponse } from 'common/interfaces'
import { ISession } from 'common/models/remote-assistance'
import { updateSession, createSessionAction } from 'common/api/remote-assistance'
import { lockDevice } from 'common/api/devices'
import { sessionByIdOptions, deviceByIdOptions } from 'common/query-options'
import { createSocket, Socket, CommandRequest, CommandResponse } from 'common/socket.io'
import {
  firstPingAction,
  pingAction,
  allowRemoteAssistanceAction,
  screencastAction,
  screenshotAction,
  getStatusAction,
  closeSocketAction,
} from 'common/remote-actions'
import { compareVersions, downloadImage, downloadText } from 'common/helper/helper'

export type RemoteAssistanceState = {
  connected: boolean
  status: 'pending' | 'access-requested' | 'access-denied' | 'timeout' | 'error' | 'success'
  deviceStatus: object | null
  fps: number
}

export type RemoteAssistanceAction =
  | {
      type:
        | 'connected'
        | 'disconnected'
        | 'pending'
        | 'access-requested'
        | 'access-denied'
        | 'timeout'
        | 'error'
        | 'success'
    }
  | {
      type: 'device-status'
      deviceStatus: object
    }
  | {
      type: 'fps'
      fps: number
    }

export type RemoteAssistanceScreenshotState = {
  screenshot: string
  loading: boolean
}

export type RemoteAssistanceScreenshotAction =
  | {
      type: 'screenshot'
      screenshot: string
    }
  | {
      type: 'start-loading' | 'stop-loading' | 'reset'
    }

export type RemoteAssistanceTestSpeedState = {
  testSpeed: object | null // TODO: Stricter typing needed.
  loading: boolean
  error: string | null
}

export type RemoteAssistanceTestSpeedAction =
  | {
      type: 'test-speed-updated' | 'test-speed-completed'
      testSpeed: object
    }
  | {
      type: 'start-loading' | 'stop-loading' | 'reset'
    }
  | {
      type: 'error'
      error: string
    }

export type RemoteAssistanceControls = {
  command: (args: CommandRequest) => void
  retry: () => void
  close: () => Promise<AxiosResponse<IResponse<ISession>>>
}

const remoteAssistanceInitialState: RemoteAssistanceState = {
  connected: false,
  status: 'pending',
  deviceStatus: null,
  fps: 10,
}

const remoteAssistanceReducer = (
  state: RemoteAssistanceState,
  action: RemoteAssistanceAction,
): RemoteAssistanceState => {
  switch (action.type) {
    case 'connected':
      return { ...state, connected: true }
    case 'disconnected':
      return { ...state, connected: false }
    case 'pending':
      return { ...remoteAssistanceInitialState }
    case 'access-requested':
      return { ...state, status: 'access-requested' }
    case 'access-denied':
      return { ...state, status: 'access-denied' }
    case 'timeout':
      return { ...state, status: 'timeout' }
    case 'error':
      return { ...state, status: 'error' }
    case 'success':
      return { ...state, status: 'success' }
    case 'device-status':
      return { ...state, deviceStatus: action.deviceStatus }
    case 'fps':
      return { ...state, fps: action.fps }
    default:
      return state
  }
}

const remoteAssistanceScreenshotInitialState: RemoteAssistanceScreenshotState = {
  screenshot: '',
  loading: true,
}

const remoteAssistanceScreenshotReducer = (
  state: RemoteAssistanceScreenshotState,
  action: RemoteAssistanceScreenshotAction,
): RemoteAssistanceScreenshotState => {
  switch (action.type) {
    case 'screenshot':
      return { ...state, screenshot: action.screenshot }
    case 'start-loading':
      return state.loading ? state : { ...state, loading: true }
    case 'stop-loading':
      return !state.loading ? state : { ...state, loading: false }
    case 'reset':
      return { ...remoteAssistanceScreenshotInitialState }
    default:
      return state
  }
}

const remoteAssistanceTestSpeedInitialState: RemoteAssistanceTestSpeedState = {
  testSpeed: null,
  loading: false,
  error: null,
}

const remoteAssistanceTestSpeedReducer = (
  state: RemoteAssistanceTestSpeedState,
  action: RemoteAssistanceTestSpeedAction,
): RemoteAssistanceTestSpeedState => {
  switch (action.type) {
    case 'test-speed-updated':
      return { ...state, testSpeed: action.testSpeed, loading: true, error: null }
    case 'test-speed-completed':
      return { ...state, testSpeed: action.testSpeed, loading: false, error: null }
    case 'start-loading':
      return { ...state, loading: true, error: null }
    case 'stop-loading':
      return { ...state, loading: false }
    case 'error':
      return { ...state, testSpeed: null, loading: false, error: action.error }
    case 'reset':
      return { ...remoteAssistanceTestSpeedInitialState }
    default:
      return state
  }
}

export const RemoteAssistanceContext = createContext<RemoteAssistanceState>(
  remoteAssistanceInitialState,
  //
)

export const RemoteAssistanceScreenshotContext = createContext<RemoteAssistanceScreenshotState>(
  remoteAssistanceScreenshotInitialState,
  //
)

export const RemoteAssistanceTestSpeedContext = createContext<RemoteAssistanceTestSpeedState>(
  remoteAssistanceTestSpeedInitialState,
  //
)

export const RemoteAssistanceControlsContext = createContext<RemoteAssistanceControls>({} as RemoteAssistanceControls)

export type RemoteAssistanceProviderProps = {
  sessionId: string
  deviceId: string
  macAddress: string
  mandatoryUserConsent: boolean
  controlVersion?: string | null
  remoteSecretKey: string
  children: React.ReactNode
}

const RemoteAssistanceProvider = ({
  sessionId,
  deviceId,
  macAddress,
  mandatoryUserConsent,
  controlVersion,
  remoteSecretKey,
  children,
}: RemoteAssistanceProviderProps) => {
  const [state, dispatch] = useReducer(
    remoteAssistanceReducer,
    remoteAssistanceInitialState,
    //
  )
  const [screenshotState, screenshotDispatch] = useReducer(
    remoteAssistanceScreenshotReducer,
    remoteAssistanceScreenshotInitialState,
    //
  )

  const [testSpeedState, testSpeedDispatch] = useReducer(
    remoteAssistanceTestSpeedReducer,
    remoteAssistanceTestSpeedInitialState,
    //
  )

  const timeoutRef = useRef(0)

  const firstPingIntervalRef = useRef(0)
  const heartbeatIntervalRef = useRef(0)

  const socketRef = useRef<Socket | null>(null)

  const getSocket = () => {
    if (socketRef.current !== null) {
      return socketRef.current as Socket
    }

    const socket =
      !!controlVersion &&
      !!remoteAssistanceSecureConnectVersion &&
      compareVersions(controlVersion, remoteAssistanceSecureConnectVersion)
        ? createSocket(remoteSecretKey)
        : createSocket()

    socketRef.current = socket

    return socket
  }

  const socketConnect = () => {
    const socket = getSocket()

    socket.connect()
  }

  const socketDisconnect = () => {
    const socket = getSocket()

    socket.disconnect()
  }

  const onCommandRequest = (args: CommandRequest) => {
    const socket = getSocket()

    socket.emit('remote_assistance_command_request', args)
  }

  const setFirstPingInterval = () => {
    firstPingIntervalRef.current = window.setInterval(() => {
      onCommandRequest(firstPingAction())
    }, 2000)
  }

  const setHeartbeatInterval = () => {
    heartbeatIntervalRef.current = window.setInterval(() => {
      onCommandRequest(screencastAction({ playback: 2 }))
    }, 45000)
  }

  const queryClient = useQueryClient()

  // TODO: Think of using the useMutationState() hook.

  const updateSessionMutation = useMutation({
    mutationFn: updateSession,
    onSuccess: (res) => {
      const session = res.data.resource

      queryClient.setQueryData(sessionByIdOptions(session.id).queryKey, session)

      // TODO: Do proper socket closing on the device's end.
      onCommandRequest(closeSocketAction())
    },
  })

  const createSessionActionMutation = useMutation({
    mutationFn: createSessionAction,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [QueryType.SESSION_ACTIONS] }),
    meta: {
      skipDefaultOnSuccess: true,
    },
  })

  const lockDeviceMutation = useMutation({
    mutationFn: lockDevice,
    onSuccess: (res) => {
      const device = res.data.resource

      queryClient.setQueryData(deviceByIdOptions(device.id).queryKey, device)
    },
  })

  useEffect(() => {
    const socket = getSocket()

    const onConnect = () => {
      if (!state.connected) {
        dispatch({ type: 'connected' })

        timeoutRef.current = window.setTimeout(() => {
          dispatch({ type: 'timeout' })

          socketDisconnect()

          clearInterval(firstPingIntervalRef.current)
          clearInterval(heartbeatIntervalRef.current)
        }, 20000)

        socket.emit('remote_assistance_connect', {
          mac_address: macAddress,
        })
      }
    }

    socket.on('connect', onConnect)

    return () => {
      socket.off('connect', onConnect)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.connected, macAddress])

  useEffect(() => {
    const socket = getSocket()

    const onConnectError = () => {
      if (!socket.active) {
        dispatch({ type: 'error' })

        clearTimeout(timeoutRef.current)

        clearInterval(firstPingIntervalRef.current)
        clearInterval(heartbeatIntervalRef.current)
      }
    }

    socket.on('connect_error', onConnectError)

    return () => {
      socket.off('connect_error', onConnectError)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const socket = getSocket()

    const onDisconnect = () => {
      if (!socket.active) {
        dispatch({ type: 'disconnected' })

        // If pending or access is requested, let it reach timeout.

        clearInterval(firstPingIntervalRef.current)
        clearInterval(heartbeatIntervalRef.current)
      }
    }

    socket.on('disconnect', onDisconnect)

    return () => {
      socket.off('disconnect', onDisconnect)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const socket = getSocket()

    const onRemoteAssistanceConnected = () => {
      onCommandRequest(firstPingAction())

      setFirstPingInterval()
    }

    socket.on('remote_assistance_connected', onRemoteAssistanceConnected)

    return () => {
      socket.off('remote_assistance_connected', onRemoteAssistanceConnected)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const socket = getSocket()

    const onRemoteAssistanceDisconnected = () => {
      socketDisconnect()
    }

    socket.on('disconnected', onRemoteAssistanceDisconnected)

    return () => {
      socket.off('disconnected', onRemoteAssistanceDisconnected)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const socket = getSocket()

    const onCommandResponse = ({
      //
      remote_action_id,
      code,
      error_message,
      image,
      status_payload,
      extra_content,
    }: CommandResponse) => {
      const init = () => {
        onCommandRequest(pingAction())

        onCommandRequest(screenshotAction())
        onCommandRequest(getStatusAction())

        onCommandRequest(screencastAction({ playback: 1, fps: state.fps }))

        setHeartbeatInterval()
      }

      if (!remote_action_id || !code) return

      if (remote_action_id === RemoteActionId.FIRST_PING) {
        if (mandatoryUserConsent) {
          dispatch({ type: 'access-requested' })

          onCommandRequest(allowRemoteAssistanceAction())
        } else {
          init()
        }

        clearInterval(firstPingIntervalRef.current)

        return
      }

      if (remote_action_id === RemoteActionId.PING) {
        if (code === 200) {
          dispatch({ type: 'success' })

          clearTimeout(timeoutRef.current)
        }

        return
      }

      if (remote_action_id === RemoteActionId.ALLOW_REMOTE_ASSISTANCE) {
        if (code === 200) {
          init()
        }

        if (code === 401) {
          dispatch({ type: 'access-denied' })

          socketDisconnect()

          clearTimeout(timeoutRef.current)
        }

        if (code === 408) {
          dispatch({ type: 'timeout' })

          socketDisconnect()

          clearTimeout(timeoutRef.current)
        }

        return
      }

      if (!!image) {
        screenshotDispatch({ type: 'screenshot', screenshot: `data:image/jpg;base64,${image}` })
        screenshotDispatch({ type: 'stop-loading' })
      }

      if (code > 200) {
        screenshotDispatch({ type: 'stop-loading' })

        toast(`Couldn't execute the ${remoteAssistanceUserActionMap[remote_action_id] || remote_action_id} action.`, {
          type: 'error',
        })
      }

      // TODO: Issues with SCREENCAST, SCREENSHOT, GET_STATUS, REBOOT, RESET, and TEST_SPEED.
      if (remoteAssistanceUserActionMap.hasOwnProperty(remote_action_id)) {
        createSessionActionMutation.mutate({
          operator_device_id: deviceId,
          operator_remote_assistance_session_id: sessionId,
          action: remote_action_id,
          response_code: code,
          response_data: error_message || 'Action has been submitted and executed.',
        })
      }

      if (remote_action_id === RemoteActionId.GET_STATUS) {
        if (code === 200) {
          dispatch({ type: 'device-status', deviceStatus: JSON.parse(status_payload!) })
        }

        return
      }

      if (remote_action_id === RemoteActionId.KEY_PRESS_MUTE || remote_action_id === RemoteActionId.VOLUME) {
        onCommandRequest(getStatusAction())

        return
      }

      if (remote_action_id === RemoteActionId.DOWNLOAD_SCREENSHOT) {
        if (code === 200) {
          downloadImage(image!)
        }

        return
      }

      if (remote_action_id === RemoteActionId.LOG) {
        if (code === 200) {
          downloadText(extra_content as string)
        }

        return
      }

      if (remote_action_id === RemoteActionId.INSTALL_APP) {
        if (code === 200) {
          toast('App installed successfully.', { type: 'success' })
        }

        onCommandRequest(getStatusAction())

        return
      }

      if (remote_action_id === RemoteActionId.UNINSTALL_APP) {
        if (code === 200) {
          toast('App uninstalled successfully.', { type: 'success' })
        }

        onCommandRequest(getStatusAction())

        return
      }

      if (remote_action_id === RemoteActionId.NOTIFICATION) {
        if (code === 200) {
          toast('Notification sent successfully.', { type: 'success' })
        }

        onCommandRequest(getStatusAction())

        return
      }

      if (remote_action_id === RemoteActionId.NOTIFICATION_REMOVE) {
        if (code === 200) {
          toast('Notification removed successfully.', { type: 'success' })
        }

        onCommandRequest(getStatusAction())

        return
      }

      if (remote_action_id === RemoteActionId.TEST_SPEED) {
        if (code === 106) {
          testSpeedDispatch({ type: 'test-speed-updated', testSpeed: extra_content as object })
        }

        if (code === 200) {
          testSpeedDispatch({ type: 'test-speed-completed', testSpeed: extra_content as object })
        }

        if (code > 200) {
          testSpeedDispatch({ type: 'error', error: error_message || 'Something went wrong.' })
        }

        return
      }
    }

    socket.on('remote_assistance_command_response', onCommandResponse)

    return () => {
      socket.off('remote_assistance_command_response', onCommandResponse)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, deviceId, mandatoryUserConsent, state.fps])

  useEffect(() => {
    socketConnect()

    const timeoutId = timeoutRef.current
    const firstPingIntervalId = firstPingIntervalRef.current
    const heartbeatIntervalId = heartbeatIntervalRef.current

    return () => {
      socketDisconnect()

      clearTimeout(timeoutId)

      clearInterval(firstPingIntervalId)
      clearInterval(heartbeatIntervalId)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const command = useCallback(
    (args: CommandRequest) => {
      if (args.screenshot) {
        screenshotDispatch({ type: 'start-loading' })
      }

      if (args.remote_action_id === RemoteActionId.TEST_SPEED) {
        testSpeedDispatch({ type: 'start-loading' })
      }

      // TODO: Feels like a middleware.

      if (args.remote_action_id === RemoteActionId.SCREENCAST) {
        if (args.playback === 0) {
          clearInterval(heartbeatIntervalRef.current)

          onCommandRequest(args)
        }

        if (args.playback === 1) {
          clearInterval(heartbeatIntervalRef.current)

          if (!!args.fps) {
            dispatch({ type: 'fps', fps: args.fps })

            onCommandRequest({ ...args })
          } else {
            onCommandRequest({ ...args, fps: state.fps })
          }

          setHeartbeatInterval()
        }

        return
      }

      if (args.remote_action_id === RemoteActionId.LOCK_UNLOCK) {
        const device = queryClient.getQueryData(deviceByIdOptions(deviceId).queryKey)!

        lockDeviceMutation.mutate(
          { id: device.id, lock: !device.lock ? 1 : 0 },
          {
            onSuccess: () => {
              onCommandRequest(args)
            },
            onError: () => {
              screenshotDispatch({ type: 'stop-loading' })
            },
          },
        )

        return
      }

      onCommandRequest(args)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deviceId, state.fps],
  )

  const retry = useCallback(() => {
    dispatch({ type: 'pending' })
    screenshotDispatch({ type: 'reset' })
    testSpeedDispatch({ type: 'reset' })

    socketConnect()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const close = useCallback(() => {
    return updateSessionMutation.mutateAsync({ id: sessionId, closed: 1 })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId])

  const controls = useMemo(
    () => ({
      command,
      retry,
      close,
    }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [command, retry, close],
  )

  return (
    <RemoteAssistanceContext.Provider value={state}>
      <RemoteAssistanceScreenshotContext.Provider value={screenshotState}>
        <RemoteAssistanceTestSpeedContext.Provider value={testSpeedState}>
          <RemoteAssistanceControlsContext.Provider value={controls}>
            {children}
          </RemoteAssistanceControlsContext.Provider>
        </RemoteAssistanceTestSpeedContext.Provider>
      </RemoteAssistanceScreenshotContext.Provider>
    </RemoteAssistanceContext.Provider>
  )
}

export default RemoteAssistanceProvider
