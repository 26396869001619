import io, { Socket as SocketIO } from 'socket.io-client'
import { INotification } from './models/notification'

const urlSocketLegacy = process.env.REACT_APP_PHP_DOMAIN_SOCKET
const urlSocket = process.env.REACT_APP_PHP_DOMAIN_REMOTE

export type CommandResponse = {
  remote_action_id: string
  code: number
  error_message?: string
  image?: string
  status_payload?: string
  extra_content?: string | object
}

export type CommandRequest = {
  remote_action_id: string
  remote_action_type: number
  screenshot?: boolean
  playback?: 0 | 1 | 2
  fps?: number
  key_code?: number
  input_text?: string
  input_text_length?: number
  command?: string
  lines?: number
  type?: 1 | 0
  volume_index?: number
  x?: number
  y?: number
  apk_url?: string
  package_name?: string
  notification_id?: string
  notification?: INotification
}

export type ListenEvents = {
  remote_assistance_connected: () => void
  disconnected: () => void // remote_assistance_disconnected
  remote_assistance_command_response: (args: CommandResponse) => void
}

export type EmitEvents = {
  remote_assistance_connect: (args: { mac_address: string }) => void
  remote_assistance_command_request: (args: CommandRequest) => void
}

export type Socket = SocketIO<ListenEvents, EmitEvents>

export const createSocket = (token?: string) => {
  const url = token ? urlSocket : urlSocketLegacy

  return io(url ?? '', {
    query: token ? { token } : undefined,
    transports: ['websocket'],
    autoConnect: false,
  }) as Socket
}
