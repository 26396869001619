export enum RoleId {
  SYS_OWNER = 'SYS_OWNER',
  SYS_ADMIN = 'SYS_ADMIN',
  OPR_OWNER = 'OPR_OWNER',
  OPR_ADMIN = 'OPR_ADMIN',
  OPR_EDITOR = 'OPR_EDITOR',
  OPR_VIEWER = 'OPR_VIEWER',
  OPR_SUPPORT_LINE_1 = 'OPR_SUPPORT_LINE_1',
  OPR_SUPPORT_LINE_2 = 'OPR_SUPPORT_LINE_2',
}

export enum RouteId {
  USER_LIST = 'auth.users.list',
  USER_READ = 'auth.users.read',
  USER_CREATE = 'auth.users.create',
  USER_UPDATE = 'auth.users.update',
  USER_DELETE = 'auth.users.delete',
  USER_DELETE_MANY = 'auth.users.delete-many',
  OPERATOR_LIST = 'auth.operators.list',
  OPERATOR_READ = 'auth.operators.read',
  OPERATOR_CREATE = 'auth.operators.create',
  OPERATOR_UPDATE = 'auth.operators.update',
  OPERATOR_DELETE = 'auth.operators.delete',
  OPERATOR_DELETE_MANY = 'auth.operators.delete-many',
  ALARM_LIST = 'core.failures.list',
  ALARM_ACKNOWLEDGE_MANY = 'core.failures.acknowledge-list',
  DEVICE_LIST = 'core.operator-devices.read',
  DEVICE_READ = 'core.operator-devices-by-id.read',
  DEVICE_CREATE = 'core.operator-devices.create',
  DEVICE_UPDATE = 'core.operator-devices-by-id.update',
  DEVICE_LOCK = 'core.operator-devices-by-id.lock',
  DEVICE_DELETE = 'core.operator-devices-by-id.delete',
  DEVICE_DELETE_MANY = 'core.operator-devices.delete-many',
  DEVICE_DATA_READ = 'core.operator-devices-by-id.data',
  DEVICE_STATUS_V2_READ = 'core.operator-devices-by-id.status',
  DEVICE_STATUS_READ = 'core.query-status.read',
  BULK_ACTION_LIST = 'core.operator-bulk-actions.list',
  BULK_ACTION_READ = 'core.operator-bulk-actions.read',
  BULK_ACTION_CREATE = 'core.operator-bulk-actions.create',
  DEVICE_PROFILE_LIST = 'core.operator-device-types.list',
  DEVICE_PROFILE_READ = 'core.operator-device-types.read',
  DEVICE_PROFILE_CREATE = 'core.operator-device-types.create',
  DEVICE_PROFILE_UPDATE = 'core.operator-device-types.update',
  DEVICE_PROFILE_DELETE = 'core.operator-device-types.delete',
  DEVICE_PROFILE_DELETE_MANY = 'core.operator-device-types.delete-many',
  APP_LIST = 'core.device-type-apps.list',
  APP_READ = 'core.device-type-apps.read',
  APP_CREATE = 'core.device-type-apps.create',
  APP_UPDATE = 'core.device-type-apps.update',
  APP_DELETE = 'core.device-type-apps.delete',
  APP_PERMISSIONS_LIST = 'core.device-type-app-permissions.list',
  APP_PERMISSIONS_READ = 'core.device-type-app-permissions.read',
  APP_PERMISSIONS_CREATE = 'core.device-type-app-permissions.create',
  APP_PERMISSIONS_UPDATE = 'core.device-type-app-permissions.update',
  APP_PERMISSIONS_DELETE = 'core.device-type-app-permissions.delete',
  APP_CONFIGURATIONS_LIST = 'core.device-type-app-configs.list',
  APP_CONFIGURATIONS_READ = 'core.device-type-app-configs.read',
  APP_CONFIGURATIONS_CREATE = 'core.device-type-app-configs.create',
  APP_CONFIGURATIONS_UPDATE = 'core.device-type-app-configs.update',
  APP_CONFIGURATIONS_DELETE = 'core.device-type-app-configs.delete',
  DEVICE_PROFILE_ROMS_READ = 'core.device-profile-roms.read',
  DEVICE_PROFILE_ROMS_UPDATE = 'core.device-profile-roms.update',
  DEVICE_PROFILE_CONFIGURATIONS_READ = 'core.operator-device-type-configs.list',
  DEVICE_PROFILE_CONFIGURATIONS_UPDATE = 'core.operator-device-type-configs.add',
  ROM_LIST = 'core.operator-roms.list',
  ROM_READ = 'core.operator-roms.read',
  ROM_CREATE = 'core.operator-roms.create',
  ROM_UPDATE = 'core.operator-roms.update',
  ROM_DELETE = 'core.operator-roms.delete',
  ROM_DELETE_MANY = 'core.operator-roms.delete-many',
  SESSION_LIST = 'core.remote-assistance-sessions.list',
  SESSION_READ = 'core.remote-assistance-sessions.read',
  SESSION_CREATE = 'core.remote-assistance-sessions.create',
  SESSION_UPDATE = 'core.remote-assistance-sessions.update',
  SESSION_ACTION_LIST = 'core.operator-remote-assistance-session-actions.list',
  SESSION_ACTION_READ = 'core.operator-remote-assistance-session-actions.read',
  SESSION_ACTION_CREATE = 'core.operator-remote-assistance-session-actions.create',
  SESSION_ACTION_UPDATE = 'core.operator-remote-assistance-session-actions.update',
}

export enum QueryType {
  LOGIN_OPTIONS = 'LOGIN_OPTIONS',
  USERS = 'USERS',
  USER_ROLES = 'USER_ROLES',
  USER_OPERATORS = 'USER_OPERATORS',
  OPERATORS = 'OPERATORS',
  OPERATOR_TYPES = 'OPERATOR_TYPES',
  OPERATOR_SERVICE_TYPES = 'OPERATOR_SERVICE_TYPES',
  ALARMS = 'ALARMS',
  ALARMS_COUNT = 'ALARMS_COUNT',
  DEVICES = 'DEVICES',
  DEVICE_BY_ID = 'DEVICE_BY_ID',
  DEVICE_DATA = 'DEVICE_DATA',
  DEVICE_STATUS_V2 = 'DEVICE_STATUS_V2',
  DEVICE_STATUS = 'DEVICE_STATUS',
  BULK_ACTIONS = 'BULK_ACTIONS',
  BULK_ACTIONS_PREVIEW = 'BULK_ACTIONS_PREVIEW',
  DEVICE_PROFILES = 'DEVICE_PROFILES',
  DEVICE_PROFILE_BY_ID = 'DEVICE_PROFILE_BY_ID',
  DEVICE_PROFILE_PLAYBACK_SUPPORTS = 'DEVICE_PROFILE_PLAYBACK_SUPPORTS',
  APPS = 'APPS',
  APP_BY_ID = 'APP_BY_ID',
  APP_PERMISSIONS = 'APP_PERMISSIONS',
  APP_CONFIGURATIONS = 'APP_CONFIGURATIONS',
  DEVICE_PROFILE_ROMS = 'DEVICE_PROFILE_ROMS',
  DEVICE_PROFILE_CONFIGURATIONS = 'DEVICE_PROFILE_CONFIGURATIONS',
  ROMS = 'ROMS',
  SESSIONS = 'SESSIONS',
  SESSION_BY_ID = 'SESSION_BY_ID',
  SESSION_ACTIONS = 'SESSION_ACTIONS',
  ADMIN_CONFIG = 'ADMIN_CONFIG',
  OPERATOR_CONFIG = 'OPERATOR_CONFIG',
  ROLES = 'ROLES',
  HANDSHAKE_VALIDATION_OPTIONS = 'HANDSHAKE_VALIDATION_OPTIONS',
  REMOTE_SECRET_KEY = 'REMOTE_SECRET_KEY',
}

export enum RemoteActionId {
  KEY_PRESS = 'KEY_PRESS',
  KEY_PRESS_LEFT = 'KEY_PRESS_LEFT',
  KEY_PRESS_RIGHT = 'KEY_PRESS_RIGHT',
  KEY_PRESS_BACK = 'KEY_PRESS_BACK',
  KEY_PRESS_APPS = 'KEY_PRESS_APPS',
  KEY_PRESS_MUTE = 'KEY_PRESS_MUTE',
  KEY_PRESS_DPAD_UP = 'KEY_PRESS_DPAD_UP',
  KEY_PRESS_DPAD_DOWN = 'KEY_PRESS_DPAD_DOWN',
  KEY_PRESS_DPAD_LEFT = 'KEY_PRESS_DPAD_LEFT',
  KEY_PRESS_DPAD_RIGHT = 'KEY_PRESS_DPAD_RIGHT',
  KEY_PRESS_DPAD_CENTER = 'KEY_PRESS_DPAD_CENTER',
  TAP_SCREEN = 'TAP_SCREEN',
  INPUT_TEXT = 'INPUT_TEXT',
  FIRST_PING = 'FIRST_PING',
  PING = 'PING',
  SCREENSHOT = 'SCREENSHOT',
  DOWNLOAD_SCREENSHOT = 'DOWNLOAD_SCREENSHOT',
  INSTALL_APP = 'INSTALL_APP',
  OPEN_APP = 'OPEN_APP',
  UNINSTALL_APP = 'UNINSTALL_APP',
  REBOOT = 'REBOOT',
  LOCK_UNLOCK = 'LOCK_UNLOCK',
  DISPLAY_ON_OFF = 'DISPLAY_ON_OFF',
  LOG = 'LOG',
  ADB = 'ADB',
  ADB_SHELL = 'ADB_SHELL',
  TEST_SPEED = 'TEST_SPEED',
  CHECK_OTA_UPDATES = 'CHECK_OTA_UPDATES',
  GET_STATUS = 'GET_STATUS',
  KEY_PRESS_HOME = 'KEY_PRESS_HOME',
  ALLOW_REMOTE_ASSISTANCE = 'ALLOW_REMOTE_ASSISTANCE',
  CLEAR_TEXT = 'CLEAR_TEXT',
  VOLUME = 'VOLUME',
  CLEAR_DATA = 'CLEAR_DATA',
  OPEN_SOCKET = 'OPEN_SOCKET',
  CLOSE_SOCKET = 'CLOSE_SOCKET',
  RESET = 'RESET',
  KEY_PRESS_SETTINGS = 'KEY_PRESS_SETTINGS',
  NOTIFICATION = 'NOTIFICATION',
  NOTIFICATION_REMOVE = 'NOTIFICATION_REMOVE',
  SCREENCAST = 'SCREENCAST',
  RELEASE_CYCLE = 'RELEASE_CYCLE',
  DEVICE_LOCK = 'DEVICE_LOCK',
  DEVICE_UNLOCK = 'DEVICE_UNLOCK',
}

export enum RemoteActionType {
  KEY_PRESS = 0,
  TAP_SCREEN = 1,
  INPUT_TEXT = 2,
  PING = 3,
  SCREENSHOT = 4,
  INSTALL_APP = 5,
  OPEN_APP = 6,
  UNINSTALL_APP = 7,
  REBOOT = 8,
  LOCK_UNLOCK = 9,
  DISPLAY_ON_OFF = 10,
  LOG = 11,
  ADB = 12,
  ADB_SHELL = 13,
  TEST_SPEED = 14,
  CHECK_OTA_UPDATES = 15,
  GET_STATUS = 16,
  KEY_PRESS_HOME = 20,
  ALLOW_REMOTE_ASSISTANCE = 21,
  CLEAR_TEXT = 22,
  VOLUME = 23,
  CLEAR_DATA = 24,
  OPEN_SOCKET = 25,
  CLOSE_SOCKET = 26,
  RESET = 27,
  KEY_PRESS_SETTINGS = 28,
  NOTIFICATION = 29,
  NOTIFICATION_REMOVE = 30,
  SCREENCAST = 34,
  RELEASE_CYCLE = 41,
  DEVICE_LOCK = 42,
  DEVICE_UNLOCK = 43,
}

export enum Order {
  ASC = 'asc',
  DESC = 'desc',
}

export enum Position {
  TOP_LEFT = 'top-left',
  TOP_CENTER = 'top-center',
  TOP_RIGHT = 'top-right',
  CENTER_LEFT = 'center-left',
  CENTER = 'center',
  CENTER_RIGHT = 'center-right',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_CENTER = 'bottom-center',
  BOTTOM_RIGHT = 'bottom-right',
}

export enum Period {
  HOURLY = 'hourly',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export enum NotificationType {
  POP_UP = 'pop-up',
  FULLSCREEN = 'fullscreen',
}

export enum NotificationFrequency {
  ONCE = 'once',
  ON_BOOT = 'boot',
  SCHEDULED = 'schedule',
}

export enum AlarmType {
  PUSH = 'PUSH',
  OTA = 'OTA',
  INSTALL = 'INSTALL',
  SOCKET = 'SOCKET',
  SPEEDTEST = 'SPEEDTEST',
  SYNC = 'SYNC',
}
